<template lang="pug">
v-dialog(
  v-model="dialog"
  max-width="800"
)
  template(v-slot:activator="{ on, attrs }")
    v-btn(
      v-bind="attrs"
      v-on="on"
      class="ma-2 font-weight-bold text-button" dark color="primary")
      v-icon(dark) mdi-calculator-variant-outline
      span(class="font-weight-bold") 設定查詢條件
  v-card
    v-card-title
      v-icon mdi-calculator-variant-outline
      span(class="text-h5") 設定查詢條件
    v-card-text
      v-container
        v-row
          v-col(
            v-if="right.operate.allowQueryData"
            cols="12"
            sm="6"
            md="3"
          ) 
            v-select(
              v-model="searchPayload.parmDedicationGroup"
              :items="clcList"
              label="教會"
              hide-details
            )
          v-col(
            cols="12"
            sm="6"
            md="3"
          ) 
            v-text-field(
              v-model="searchPayload.number"
              label="奉獻編號"
              hide-details
            )
          v-col(
            cols="12"
            sm="6"
            md="3"
          )
            v-text-field(
              v-model="searchPayload.name"
              label="姓名"
              hide-details
            )
          v-col(
            cols="12"
            sm="6"
            md="3"
          )
            v-text-field(
              v-model="searchPayload.phone"
              label="手機"
              hide-details
            )
          v-col(
            cols="12"
            sm="6"
            md="6"
          )
            v-select(
              v-model="searchPayload.parmDedicationType"
              :items="dedicationTypeOptions"
              label="奉獻類別"
              hide-details
            )
          v-col(
            cols="12"
            sm="6"
            md="6"
          )
            v-select(
              v-model="searchPayload.parmDedicationWay"
              :items="dedicationWayOptions"
              label="奉獻方式"
              hide-details
            )
          v-col(
            cols="12"
            sm="12"
            md="12"
          )
            v-select(
              v-model="searchPayload.uniformNoType"
              :items="dedicationUniformNoTypeOptions"
              label="收據抬頭"
              hide-details
            )
          v-col(v-if="right.operate.allowQueryData")
            v-text-field(
              v-model="searchPayload.startDedicationDate"
              label="奉獻日期(起)"
              type="date"
              hide-details
            )
          v-col(v-if="right.operate.allowQueryData")
            v-text-field(
              v-model="searchPayload.endDedicationDate"
              label="奉獻日期(迄)"
              type="date"
              hide-details
            )
          v-col(v-if="right.operate.allowQueryData")
            v-text-field(
              v-model="searchPayload.startCreated"
              label="編輯日期(起)"
              type="date"
              hide-details
            )
          v-col(v-if="right.operate.allowQueryData")
            v-text-field(
              v-model="searchPayload.endCreated"
              label="編輯日期(迄)"
              type="date"
              hide-details
            )
          v-col(
            v-if="right.operate.allowQueryData"
            cols="12"
            sm="6"
            md="3"
          )
            v-select(
              v-model="searchPayload.updateLastUserId"
              :items="lastUserOptions"
              label="編輯者"
              hide-details
            )
    v-card-actions
      v-spacer
      v-btn(
        color="blue darken-1"
        dark
        @click="handleSettingSearch"
      ) 
        v-icon(dark) mdi-magnify
        span(class="font-weight-bold") 查詢
</template>

<script>
export default {
  name: "settingDialog",
  props: {
    searchPayload: {
      type: Object,
    },
    dedicationTypeOptions: {
      type: Array,
    },
    dedicationWayOptions: {
      type: Array,
    },
    dedicationGroupOptions: {
      type: Array,
    },
    dedicationUniformNoTypeOptions: {
      type: Array,
    },
    lastUserOptions: {
      type: Array,
    }
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    clcList() {
      return this.$store.state.group.clcList.clcList;
    },
    right() {
      const currentPageRight = this.$store.state.auth.right.find((item) => { return item.router === this.$route.path })
      return currentPageRight
    },
  },
  methods: {
    handleSettingSearch() {
      this.dialog = false;
      document.cookie = `_dedication_parmDedicationGroup=${this.searchPayload.parmDedicationGroup}`;
      document.cookie = `_dedication_number = ${this.searchPayload.number}`;
      document.cookie = `_dedication_name = ${this.searchPayload.name}`;
      document.cookie = `_dedication_parmDedicationType = ${this.searchPayload.parmDedicationType}`;
      document.cookie = `_dedication_parmDedicationWay = ${this.searchPayload.parmDedicationWay}`;
      document.cookie = `_dedication_uniformNoType = ${this.searchPayload.uniformNoType}`;
      document.cookie = `_dedication_startDedicationDate = ${this.searchPayload.startDedicationDate}`;
      document.cookie = `_dedication_endDedicationDate = ${this.searchPayload.endDedicationDate}`;
      document.cookie = `_dedication_startCreated = ${this.searchPayload.startCreated}`;
      document.cookie = `_dedication_endCreated = ${this.searchPayload.endCreated}`;
      document.cookie = `_dedication_updateLastUserId = ${this.searchPayload.updateLastUserId}`;
      this.$emit("submit", this.searchPayload);
    },
  },
};
</script>
