<template lang="pug">
v-card(
  class="pa-4"
  outlined
)
  h4(class="mb-5") {{ amountData.queryDedicationTitle }}
  v-row
    v-col(
      v-for="(item, index) in amountData.queryDedicationItem"
      cols="6"
      sm="4"
      md="3"
      class="py-2")
      span {{ item.text }} : {{ amountFormat(item.value) }}
      
</template>

<script>
import { amountFormat } from "@/utils/format";
export default {
  name: "dedicationAmountSearchForm",
  props: {
    amountData: {
      type: Object,
    },
  },
  methods: {
    amountFormat(val) {
      return amountFormat(val);
    },
  },
};
</script>
