<template lang="pug">
v-container(fluid)
  div(class="d-flex justify-space-between mb-2")
    h2(class="blue-grey--text")
      v-icon(class="mr-1") mdi-currency-twd
      span 奉獻金額管理
  h3(class="mx-2") 小計
  AmountCard(
    :amountData="amountData"
  )
  div(class="d-flex")
    SettingDialog(
      :searchPayload="searchPayload"
      :dedicationTypeOptions="dedicationTypeOptions"
      :dedicationWayOptions="dedicationWayOptions"
      :dedicationGroupOptions="dedicationGroupOptions"
      :dedicationUniformNoTypeOptions="dedicationUniformNoTypeOptions"
      :lastUserOptions="lastUserOptions"
      @submit="getList"
    )
    v-btn(
      class="ma-2 font-weight-bold text-button"
      @click="getList(searchPayload)")
      v-icon mdi-refresh
      span(class="font-weight-bold") 資料查詢
    v-btn(
      class="ma-2 font-weight-bold text-button"
      color="blue-grey darken-1"
      dark
      @click="exportData")
      v-icon mdi-table-arrow-right
      span(class="font-weight-bold") 奉獻資料匯出
    AddDialog(
      v-if="amountData.authAdd && right.operate.allowAddRow"
      :addCookie="addCookie"
      :dedicationTypeOptions="dedicationTypeOptions"
      :dedicationWayOptions="dedicationWayOptions"
      :dedicationGroupOptions="dedicationGroupOptions"
      :dedicationUniformNoTypeOptions="dedicationUniformNoTypeOptions"
      @addDedication="handleAddDedication"
    )
  div
    h4(class="ma-2") 資料筆數: {{ totalRow }} 筆
  SearchTable(
    :loading="loading"
    :data="listData"
    :dedicationTypeOptions="dedicationTypeOptions"
    :dedicationWayOptions="dedicationWayOptions"
    :dedicationGroupOptions="dedicationGroupOptions"
    :dedicationUniformNoTypeOptions="dedicationUniformNoTypeOptions"
    @update="handleUpdate"
    @delete="handleDelete"
  )
</template>

<script>
import AmountCard from "@/components/dedicationAmount/amountCard";
import SearchTable from "@/components/dedicationAmount/searchTable";
import SettingDialog from "@/components/dedicationAmount/settingDialog";
import AddDialog from "@/components/dedicationAmount/addDialog";
export default {
  name: "dedicationAmountSearch",
  components: {
    AmountCard,
    SearchTable,
    SettingDialog,
    AddDialog,
  },
  data: () => ({
    analyzeData: {},
    group: [],
    listData: [],
    amountData: {},
    totalRow: 0,
    loading: false,
    dedicationTypeOptions: [],
    dedicationWayOptions: [],
    dedicationGroupOptions: [],
    dedicationUniformNoTypeOptions: [],
    lastUserOptions: [],
    searchPayload: {
      parmDedicationGroup: null,
      number: "",
      name: "",
      parmDedicationType: null,
      parmDedicationWay: null,
      startDedicationDate: null,
      endDedicationDate: null,
      startCreated: null,
      endCreated: null,
      updateLastUserId: null,
      phone: "",
    },
    addCookie: {}
  }),
  computed: {
    right() {
      const currentPageRight = this.$store.state.auth.right.find((item) => { return item.router === this.$route.path })
      return currentPageRight
    },
  },
  methods: {
    async getClcList() {
      try {
        let clcListRes = await this.$api.common.commonAPI.getClcList();
        if (clcListRes && clcListRes.data.code === 200) {
          this.$store.dispatch("group/setClcList", clcListRes.data.content);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getDedicationOption() {
      try {
        let dedicationTypeRes =
          await this.$api.common.commonAPI.getDedicationType();
        let dedicationWayRes =
          await this.$api.common.commonAPI.getDedicationWay();
        let dedicationGroupRes =
          await this.$api.common.commonAPI.getDedicationGroup();
        let dedicationUniformNoTypeRes =
          await this.$api.common.commonAPI.getDedicationUniformNo();
        let lastUserRes =
          await this.$api.common.commonAPI.getLastUser();
        this.dedicationTypeOptions = [ {text: '全部', value: 0 }, ...dedicationTypeRes.data.content ];
        this.dedicationWayOptions = [ {text: '全部', value: 0 }, ...dedicationWayRes.data.content ];
        this.dedicationUniformNoTypeOptions = [ {text: '全部', value: 0 }, ...dedicationUniformNoTypeRes.data.content ];
        this.lastUserOptions = [ {text: '全部', value: '0' }, ...lastUserRes.data.content ];
        this.dedicationGroupOptions = dedicationGroupRes.data.content;
      } catch (err) {
        console.log(err);
      }
    },
    async getList(payload) {
      try {
        this.loading = true;
        let listRes =
          await this.$api.dedicationAmount.dedicationAmountAPI.getList(payload);
        if (listRes && listRes.data.code === 200) {
          this.listData = listRes.data.content.dedications;
          this.amountData = listRes.data.content.queryTitle;
          this.totalRow = listRes.data.content.totalRow
        }
        if (!listRes.data.success && listRes.data.showalert) {
          this.$store.dispatch("notification/openNotification", {
            msg: listRes.data.message,
            color: "red",
            timeout: 5000,
            showClose: false,
          });
          if (listRes.data.isRedirect) {
            this.$router.push("/permissions");
          }
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async handleAddDedication(item) {
      try {
        let addedItemRes =
          await this.$api.dedicationAmount.dedicationAmountAPI.addDedication(
            item
          );
        this.$store.dispatch("notification/openNotification", {
          msg: "已新增",
          color: "green",
          timeout: 5000,
          showClose: false,
        });
        const addedItem = {
          id: addedItemRes.data.content.id,
          updateLastUser: addedItemRes.data.content.updateLastUser,
          updateTime: addedItemRes.data.content.updateTime,
          ...item,
        };
        document.cookie = `_dedication_add_parmDedicationGroup = ${item.parmDedicationGroup}`;
        document.cookie = `_dedication_add_parmDedicationType = ${item.parmDedicationType}`;
        document.cookie = `_dedication_add_parmDedicationWay = ${item.parmDedicationWay}`;
        document.cookie = `_dedication_add_uniformNoType = ${item.uniformNoType}`;
        document.cookie = `_dedication_add_dedicationDate = ${item.dedicationDate}`;
        this.listData.unshift(addedItem);
      } catch (err) {
        console.log(err);
      }
    },
    async handleDelete(item) {
      try {
        await this.$api.dedicationAmount.dedicationAmountAPI.deleteDedication({
          id: item.id,
        });
        this.$store.dispatch("notification/openNotification", {
          msg: "已刪除",
          color: "green",
          timeout: 5000,
          showClose: false,
        });
        this.getList(this.searchPayload);
      } catch (err) {
        console.log(err);
      }
    },
    async handleUpdate(item) {
      try {
        await this.$api.dedicationAmount.dedicationAmountAPI.modifyDedication(
          item
        );
        this.$store.dispatch("notification/openNotification", {
          msg: `${item.name} 資料已更新`,
          color: "green",
          timeout: 5000,
          showClose: false,
        });
        this.getList(this.searchPayload);
      } catch (err) {
        console.log(err);
      }
    },
    async exportData() {
      try {
        let exportRes =
          await this.$api.dedicationAmount.dedicationAmountAPI.exportData({
            ...this.searchPayload,
            responseType: "blob",
          });
        const blob = new Blob([exportRes.data], {
          type: exportRes.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.click();
        link.remove();
      } catch (err) {
        console.log(err);
      }
    },
  },
  created() {
    // 查詢 cookie
    if(this.right.operate.allowQueryData){
      const current = new Date();
      const dedication_parmDedicationGroup_cookie = document.cookie
        .split(";")
        .find((item) => {
          return item.indexOf("_dedication_parmDedicationGroup") !== -1;
        });
      const dedication_number_cookie = document.cookie.split(";").find((item) => {
        return item.indexOf("_dedication_number") !== -1;
      });
      const dedication_name_cookie = document.cookie.split(";").find((item) => {
        return item.indexOf("_dedication_name") !== -1;
      });
      const dedication_parmDedicationType_cookie = document.cookie
        .split(";")
        .find((item) => {
          return item.indexOf("_dedication_parmDedicationType") !== -1;
        });
      const dedication_parmDedicationWay_cookie = document.cookie
        .split(";")
        .find((item) => {
          return item.indexOf("_dedication_parmDedicationWay") !== -1;
        });
      const _dedication_uniformNoType_cookie = document.cookie
        .split(";")
        .find((item) => {
          return item.indexOf("_dedication_uniformNoType") !== -1;
        });
      const dedication_startDedicationDate_cookie = document.cookie
        .split(";")
        .find((item) => {
          return item.indexOf("_dedication_startDedicationDate") !== -1;
        });
      const dedication_endDedicationDate_cookie = document.cookie
        .split(";")
        .find((item) => {
          return item.indexOf("_dedication_endDedicationDate") !== -1;
        });
      const dedication_startCreated_cookie = document.cookie
        .split(";")
        .find((item) => {
          return item.indexOf("_dedication_startCreated") !== -1;
        });
      const dedication_endCreated_cookie = document.cookie
        .split(";")
        .find((item) => {
          return item.indexOf("_dedication_endCreated") !== -1;
        });
      const dedication_updateLastUserId_cookie = document.cookie
        .split(";")
        .find((item) => {
          return item.indexOf("_dedication_updateLastUserId") !== -1;
        });
      this.searchPayload = {
        parmDedicationGroup: dedication_parmDedicationGroup_cookie
          ? Number(
              dedication_parmDedicationGroup_cookie.split(
                "_dedication_parmDedicationGroup="
              )[1]
            )
          : 1,
        number: dedication_number_cookie
          ? dedication_number_cookie.split("_dedication_number=")[1]
          : "",
        name: dedication_name_cookie
          ? dedication_name_cookie.split("_dedication_name=")[1]
          : "",
        parmDedicationType: dedication_parmDedicationType_cookie
          ? Number(
              dedication_parmDedicationType_cookie.split(
                "_dedication_parmDedicationType="
              )[1]
            )
          : 0,
        parmDedicationWay: dedication_parmDedicationWay_cookie
          ? Number(
              dedication_parmDedicationWay_cookie.split(
                "_dedication_parmDedicationWay="
              )[1]
            )
          : 0,
        uniformNoType: _dedication_uniformNoType_cookie ? Number(
          _dedication_uniformNoType_cookie.split(
                "_dedication_uniformNoType="
              )[1]
            )
          : 0,
        startDedicationDate: dedication_startDedicationDate_cookie
          ? dedication_startDedicationDate_cookie.split(
              "_dedication_startDedicationDate="
            )[1]
          : `${current.getFullYear()}-01-01`,
        endDedicationDate: dedication_endDedicationDate_cookie
          ? dedication_endDedicationDate_cookie.split(
              "_dedication_endDedicationDate="
            )[1]
          : `${current.getFullYear()}-12-31`,
        startCreated: dedication_startCreated_cookie
          ? dedication_startCreated_cookie.split("_dedication_startCreated=")[1]
          : "",
        endCreated: dedication_endCreated_cookie
          ? dedication_endCreated_cookie.split("_dedication_endCreated=")[1]
          : "",
        updateLastUserId: dedication_updateLastUserId_cookie 
          ? dedication_updateLastUserId_cookie.split("_dedication_updateLastUserId=")[1]
          : "0",
        allowQueryData: this.right.operate.allowQueryData
      };
    } else {
      document.cookie = `_dedication_parmDedicationGroup=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      document.cookie = `_dedication_number=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      document.cookie = `_dedication_name=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      document.cookie = `_dedication_parmDedicationType=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      document.cookie = `_dedication_parmDedicationWay=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      document.cookie = `_dedication_uniformNoType=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      document.cookie = `_dedication_startDedicationDate=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      document.cookie = `_dedication_endDedicationDate=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      document.cookie = `_dedication_startCreated=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      document.cookie = `_dedication_endCreated=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      document.cookie = `_dedication_updateLastUserId=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
    this.searchPayload.allowQueryData = this.right.operate.allowQueryData
    this.getClcList();
    this.getDedicationOption();
    // this.getList(this.searchPayload);
  },
};
</script>
