<template lang="pug">
v-dialog(
  v-model="dialog"
  max-width="800"
)
  template(v-slot:activator="{ on, attrs }")
    v-btn(
      v-bind="attrs"
      v-on="on"
      class="ma-2 ml-auto font-weight-bold text-button" dark color="success")
      v-icon(dark) mdi-plus
      span(class="font-weight-bold") 新增
  v-form(
    ref="form"
    v-model="valid"
    @submit.prevent="handleAddDedication")
    v-card
      v-card-title
        v-icon mdi-plus
        span(class="text-h5") 新增一筆奉獻
      v-card-text
        v-container
          v-row
            v-col
              v-select(
                v-model="addData.parmDedicationGroup"
                :items="clcList"
                :rules="rules"
                label="所屬教會"
                required
                hide-details
              )
            v-col
              v-text-field(
                v-model="addData.number"
                :rules="rules"
                label="奉獻編號"
                type="number"
                required
              )
            v-col
              v-text-field(
                v-model="addData.name"
                :rules="rules"
                label="姓名"
                required
              )
            v-col
              v-text-field(
                v-model="addData.phone"
                label="手機"
              )
          v-row
            v-col(
              cols="12"
              sm="6"
              md="4"
            )
              v-text-field(
                v-model="addData.amount"
                :rules="rules"
                label="奉獻金額"
                type="number"
                required
              )
            v-col(
              cols="12"
              sm="6"
              md="4"
            )
              v-select(
                v-model="addData.parmDedicationType"
                :items="dedicationTypeOptions"
                :rules="rules"
                label="奉獻類別"
                required
                hide-details
              )
            v-col(
              cols="12"
              sm="6"
              md="4"
            )
              v-select(
                v-model="addData.parmDedicationWay"
                :items="dedicationWayOptions"
                label="奉獻方式"
                required
                hide-details
              )
            v-col(
                cols="12"
                sm="12"
                md="12"
              )
              v-select(
                v-model="addData.uniformNoType"
                :items="dedicationUniformNoTypeOptions"
                label="收據抬頭"
                hide-details
              )
            v-col(
              cols="12"
              sm="6"
              md="3"
            )
              v-text-field(
                v-model="addData.dedicationDate"
                label="奉獻日期"
                type="date"
                required
              )
      v-card-actions
        v-spacer
        v-btn(
          color="blue darken-1"
          dark
          type="submit"
        ) 
          v-icon(dark) mdi-plus
          span(class="font-weight-bold") 新增
</template>

<script>
export default {
  name: "addDialog",
  props: {
    addCookie: {
      type: Object
    },
    dedicationTypeOptions: {
      type: Array,
    },
    dedicationWayOptions: {
      type: Array,
    },
    dedicationGroupOptions: {
      type: Array,
    },
    dedicationUniformNoTypeOptions: {
      type: Array,
    }
  },
  data: () => ({
    dialog: false,
    valid: true,
    rules: [(v) => !!v || "必填"],
    addData: {
      parmDedicationGroup: null,
      number: null,
      name: null,
      amount: null,
      parmDedicationType: 1,
      parmDedicationWay: 1,
      dedicationDate: "",
      phone: null,
      uniformNoType: null,
    },
  }),
  watch: {
    "addData.number"(val) {
      if (val && val.toString().length > 3) {
        this.getDedicationName(val.toString());
      }
    },
    dialog () {
      // cookie
      const dedication_add_parmDedicationGroup_cookie = document.cookie
      .split(";")
      .find((item) => {
        return item.indexOf("_dedication_add_parmDedicationGroup") !== -1;
      });
      const dedication_add_parmDedicationType_cookie = document.cookie
      .split(";")
      .find((item) => {
        return item.indexOf("_dedication_add_parmDedicationType") !== -1;
      });

      const dedication_add_parmDedicationWay_cookie = document.cookie
      .split(";")
      .find((item) => {
        return item.indexOf("_dedication_add_parmDedicationWay") !== -1;
      });

      const dedication_add_uniformNoType_cookie = document.cookie
      .split(";")
      .find((item) => {
        return item.indexOf("_dedication_add_uniformNoType") !== -1;
      });

      const dedication_add_dedicationDate_cookie = document.cookie
        .split(";")
        .find((item) => {
          return item.indexOf("_dedication_add_dedicationDate") !== -1;
        });
      this.addData.parmDedicationGroup = dedication_add_parmDedicationGroup_cookie
        ? Number(
          dedication_add_parmDedicationGroup_cookie.split(
              "_dedication_add_parmDedicationGroup="
            )[1]
          )
        : null;
      this.addData.parmDedicationType = dedication_add_parmDedicationType_cookie
        ? Number(
          dedication_add_parmDedicationType_cookie.split(
              "_dedication_add_parmDedicationType="
            )[1]
          )
        : null;
      this.addData.parmDedicationWay = dedication_add_parmDedicationWay_cookie
        ? Number(
          dedication_add_parmDedicationWay_cookie.split(
              "_dedication_add_parmDedicationWay="
            )[1]
          )
        : null;
      this.addData.uniformNoType = dedication_add_uniformNoType_cookie
        ? Number(
          dedication_add_uniformNoType_cookie.split(
              "_dedication_add_uniformNoType="
            )[1]
          )
        : null;
      this.addData.dedicationDate = dedication_add_dedicationDate_cookie
        ? dedication_add_dedicationDate_cookie.split(
            "_dedication_add_dedicationDate="
          )[1]
        : this.$dayjs().format("YYYY-MM-DD");
      }
  },
  computed: {
    clcList() {
      return this.$store.state.group.clcList.clcList;
    },
  },
  methods: {
    async getDedicationName(val) {
      try {
        let res =
          await this.$api.dedicationAmount.dedicationAmountAPI.getDedicationName(
            {
              number: val,
              parmChurch: this.addData.parmDedicationGroup,
            }
          );
        if (res && res.data.code === 200) {
          this.addData.name = res.data.content.name;
          this.addData.phone = res.data.content.phone;
        }
        if (!res.data.success && res.data.showalert) {
          this.$store.dispatch("notification/openNotification", {
            msg: res.data.message,
            color: "red",
            timeout: 5000,
            showClose: false,
          });
          this.addData.name = null;
          this.addData.phone = null;
        }
      } catch (err) {
        console.log(err);
      }
    },
    handleAddDedication() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.dialog = false;
        this.$emit("addDedication", this.addData);
        this.addData = {
          parmDedicationGroup: null,
          number: null,
          name: null,
          amount: null,
          parmDedicationType: 1,
          parmDedicationWay: 1,
          dedicationDate: this.$dayjs().format("YYYY-MM-DD"),
          phone: null,
          uniformNoType: null
        };
        this.$refs.form.resetValidation()
      }
    },
  },
};
</script>
