<template lang="pug">
div
  v-data-table(
    :headers="headers"
    :items="data"
    :page.sync="page"
    :items-per-page="itemsPerPage"
    :loading="loading"
    loading-text="資料載入中..."
    no-data-text="暫無資料"
    hide-default-footer
    @page-count="pageCount = $event"
  )
    template(v-slot:item.parmDedicationGroup="{ item }")
      span(v-if="item !== editingItem") {{ formatChurch(item.parmDedicationGroup) }}
      select(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.parmDedicationGroup")
        option(v-for="(church, churchIndex) in clcList" :key="churchIndex" :value="church.value") {{ church.text }}
    template(v-slot:item.number="{ item }")
      span(v-if="item !== editingItem") {{ item.number }}
      input(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.number")
    template(v-slot:item.name="{ item }")
      span(v-if="item !== editingItem") {{ item.name }}
      input(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.name")
    template(v-slot:item.phone="{ item }")
      span(v-if="item !== editingItem") {{ item.phone }}
      input(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.phone")
    template(v-slot:item.amount="{ item }")
      span(v-if="item !== editingItem") {{ amountFormat(item.amount) }}
      input(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.amount")
    template(v-slot:item.parmDedicationType="{ item }")
      span(v-if="item !== editingItem") {{ formatDedicationType(item.parmDedicationType) }}
      select(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.parmDedicationType")
        option(v-for="(type, typeIndex) in dedicationTypeOptions" :key="typeIndex" :value="type.value") {{ type.text }}
    template(v-slot:item.parmDedicationWay="{ item }")
      span(v-if="item !== editingItem") {{ formatDedicationWay(item.parmDedicationWay) }}
      select(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.parmDedicationWay")
        option(v-for="(way, wayIndex) in dedicationWayOptions" :key="wayIndex" :value="way.value") {{ way.text }}
    template(v-slot:item.uniformNoType="{ item }")
      span(v-if="item !== editingItem") {{ formatUniformNoType(item.uniformNoType) }}
      select(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.uniformNoType")
        option(v-for="(uniformNoType, wayIndex) in dedicationUniformNoTypeOptions" :key="uniformNoTypeIndex" :value="uniformNoType.value") {{ uniformNoType.text }}
    template(v-slot:item.dedicationDate="{ item }")
      span(v-if="item !== editingItem") {{ item.dedicationDate }}
      input(
        class="edit-date-input"
        type="date"
        v-if="item === editingItem"
        v-model="editingItem.dedicationDate")
    template(v-slot:item.actions="{ item }" v-if="right.operate.allowEditRow")
      v-btn(
        v-if="item === editingItem"
        @click="updateItem(item)"
        small
        dark
        color="cyan darken-4"
        class="mx-1 text-button") 儲存
      v-btn(
        v-if="item === editingItem && right.operate.allowDeleteRow"
        @click="deleteConfirmDialog=true"
        small
        dark
        color="red darken-4"
        class="mx-1 text-button") 刪除
      v-btn(
        v-if="item === editingItem"
        @click="cancelEditItem(item)"
        small
        dark
        color="yellow darken-4"
        class="mx-1 text-button") 取消
      v-btn(
        v-if="item.id && item !== editingItem"
        @click="editItem(item)"
        small
        outlined
        color="teal"
        class="mx-1 text-button") 編輯
  v-pagination(
    v-model="page"
    :length="pageCount"
  )
  v-dialog(
    v-model="deleteConfirmDialog"
    width="500"
  )
    v-form(
      ref="form"
      v-model="valid"
      @submit.prevent="deleteEditItem")
      v-card
        v-card-text(class="py-3") 是否確認刪除 {{ editingItem.name }} 的奉獻資料?
        v-card-text
          v-container
            v-text-field(
              :rules="rules"
              dense 
              outlined
              label="請輸入刪除後再按確認"
              required
            ) 
        v-card-actions
          v-spacer
          v-btn(
            text
            @click="deleteConfirmDialog = false") 取消
          v-btn(
            dark
            color="mx-1 red darken-4"
            type="submit") 確認刪除
</template>
<script>
import { amountFormat } from "@/utils/format";
import editGroup from "@/components/common/editGroup";
export default {
  name: "dedicationAmountSearchTable",
  props: {
    loading: {
      type: Boolean,
    },
    data: {
      type: Array,
    },
    dedicationTypeOptions: {
      type: Array,
    },
    dedicationWayOptions: {
      type: Array,
    },
    dedicationGroupOptions: {
      type: Array,
    },
    dedicationUniformNoTypeOptions: {
      type: Array,
    }
  },
  components: {
    editGroup,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      headers: [
        {
          text: "教會",
          align: "center",
          sortable: false,
          value: "parmDedicationGroup",
        },
        { text: "奉獻編號", align: "center", sortable: false, value: "number" },
        { text: "奉獻No.", align: "center", sortable: true, value: "numberNo" },
        { text: "奉獻者", align: "center", sortable: false, value: "name" },
        { text: "手機", align: "center", sortable: false, value: "phone" },
        {
          text: "奉獻金額",
          align: "center",
          sortable: false,
          value: "amount",
        },
        {
          text: "奉獻類別",
          align: "center",
          sortable: false,
          value: "parmDedicationType",
        },
        {
          text: "奉獻方式",
          align: "center",
          sortable: false,
          value: "parmDedicationWay",
        },
        {
          text: "收據抬頭",
          align: "center",
          sortable: false,
          value: "uniformNoType",
        },
        {
          text: "奉獻日期",
          align: "center",
          sortable: true,
          value: "dedicationDate",
        },
        {
          text: "編輯時間",
          align: "center",
          sortable: true,
          value: "updateTime",
        },
        {
          text: "編輯者",
          align: "center",
          sortable: false,
          value: "updateLastUser",
        },
        { text: "", align: "center", value: "actions", sortable: false },
      ],
      editingItem: {},
      deleteItem: {},
      deleteConfirmDialog: false,
      valid: true,
      rules: [(v) => !!v || "必填", (v) => v === "刪除" || "輸入內容不符"],
    };
  },
  watch: {
    deleteConfirmDialog(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    },
  },
  computed: {
    groups() {
      return this.$store.state.group.group;
    },
    clcList() {
      return this.$store.state.group.clcList.clcList;
    },
    right() {
      const currentPageRight = this.$store.state.auth.right.find((item) => { return item.router === this.$route.path })
      return currentPageRight
    },
  },
  methods: {
    amountFormat(val) {
      return amountFormat(val);
    },
    formatChurch(val) {
      const currentChurch = this.clcList.find((item) => {
        return item.value === val;
      });
      if (currentChurch) {
        return currentChurch.text;
      } else {
        return val;
      }
    },
    formatDedicationType(val) {
      const currentDedicationType = this.dedicationTypeOptions.find((item) => {
        return item.value === val;
      });
      if (currentDedicationType) {
        return currentDedicationType.text;
      } else {
        return '-';
      }
    },
    formatDedicationWay(val) {
      const currentDedicationWay = this.dedicationWayOptions.find((item) => {
        return item.value === val;
      });
      if (currentDedicationWay) {
        return currentDedicationWay.text;
      } else {
        return '-';
      }
    },
    formatUniformNoType(val) {
      const currentUniformNoType = this.dedicationUniformNoTypeOptions.find((item) => {
        return item.value === val;
      });
      if (currentUniformNoType) {
        return currentUniformNoType.text;
      } else {
        return '-';
      }
    },
    handleUpdateGroupId(val) {
      this.editingItem["groupId"] = val;
    },
    groupName(val) {
      const group = this.groups.find((item) => {
        return item.group_id === val;
      });
      if (group) {
        return group.group_name;
      } else {
        return "-";
      }
    },
    editItem(item) {
      this.editingItem = item;
    },
    deleteEditItem() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.$emit("delete", this.editingItem);
        this.deleteConfirmDialog = false;
        this.editingItem = {};
      }
    },
    cancelEditItem() {
      this.editingItem = {};
    },
    updateItem() {
      this.$emit("update", this.editingItem);
      this.editingItem = {};
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 8px;
}

.edit-component {
  border: 1px solid #5e6e7d;
  border-radius: 4px;
  padding: 4px 8px;
  max-width: 90px;
}
.edit-date-input {
  border: 1px solid #5e6e7d;
  border-radius: 4px;
  padding: 4px 8px;
  width: 130px;
}
.checkbox {
  width: fit-content;
  margin: 0 auto;
}
</style>
